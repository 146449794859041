<template>
<v-col class="my-0 py-0 px-0 px-md-8 pb-15">
    <v-col class="text-start mt-5 mb-3">
        <v-alert text dense color="#8061c2" border="left" class="pl-5">
            <h1 class="title mt-1">Usuarios</h1>
            <p class="desc mt-0 mb-1">Listado de usuarios registrados en la plataforma.</p>
        </v-alert>
    </v-col>

    <!-- Barra de búsqueda y filtros -->
    <v-row class="mx-3">
        <v-col cols="12" md="4" class="px-0">
            <v-text-field v-model="search" color="#8061c2" label="Buscar usuarios" append-icon="mdi-magnify" single-line hide-details outlined class="mb-2 mx-0 px-0" @input="onSearch"></v-text-field>
        </v-col>

        <!-- Select para ordenar por campo -->
        <v-col cols="12" md="3" class="px-md-2 px-0">
            <v-select v-model="orderBy" :items="orderByOptions" label="Ordenar por" outlined @change="onOrderChange"></v-select>
        </v-col>

        <!-- Select para dirección de ordenamiento -->
        <v-col cols="12" md="3" class="px-md-2 ">
            <v-select v-model="orderDirection" :items="orderDirectionOptions" label="Dirección" outlined @change="onOrderChange"></v-select>
        </v-col>

        <!-- Select para dirección de ordenamiento -->
        <v-col cols="12" md="2" class="px-0">
            <v-select v-model="userOrCreator" :items="userOptions" label="Rol" outlined @change="onOrderChange"></v-select>
        </v-col>
    </v-row>

    <!-- Tabla de datos -->
    <v-data-table :headers="headers" :items="users" :loading="loading" class="elevation-1 mx-3 px-2 py-4" :items-per-page="perPage" :server-items-length="totalUsers" :page.sync="page" :footer-props="{
          itemsPerPageOptions: [50, 100, 200, 300, -1],
          showFirstLastPage: true
      }" @update:page="onPageChange" @update:items-per-page="changePerPage">
        <!-- Columnas y Templates -->
        <template v-slot:item.logo_path="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar size="40" class="clickable-avatar" @click="showLogo(item.logo_path)" v-bind="attrs" v-on="on">
                        <v-img :src="item.logo_path" v-if="item.logo_path"></v-img>
                        <v-avatar v-else color="black">
                            <span class="white--text">{{ item.first_name.charAt(0) }}{{ item.last_name.charAt(0) }}</span>
                        </v-avatar>
                    </v-avatar>
                </template>
                <span>Ver Imagen</span>
            </v-tooltip>
        </template>

        <template v-slot:item.name="{ item }">
            {{ item.first_name }} {{ item.last_name }}
        </template>

        <template v-slot:item.role.name="{ item }">
            <v-chip :color="getRoleColor(item.role.name)" dark small>
                {{ item.role.name }}
            </v-chip>
        </template>

        <template v-slot:item.age="{ item }">
            {{ item.age_id != null ? item.age.name : 'N/A' }}
        </template>

        <template v-slot:item.gender="{ item }">
            {{ item.gender_id != null ? item.gender.name : 'N/A' }}
        </template>

        <template v-slot:item.email="{ item }">
            {{ item.email }}
        </template>

        <template v-slot:item.phone="{ item }">
            {{ item.phone_code }} {{ item.phone_number }}
        </template>

        <template v-slot:item.total_sites="{ item }">
            {{ item.total_sites }}
        </template>

        <template v-slot:item.created_at="{ item }">
            {{ item.created_at_formatted }}
        </template>

        <template v-slot:item.creator_type="{ item }">
            <v-chip :color="getCreatorColor(item.creator_type_id)" dark small>
                {{ item.creator_type_id != null ? item.creator_type.name : 'N/A' }}
            </v-chip>
        </template>

        <template v-slot:item.status_id="{ item }">
            <v-chip :color="item.status_id === 1 ? 'success' : 'error'" dark small>
                {{ item.status_id === 1 ? 'Habilitado' : 'Deshabilitado' }}
            </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
            <!-- Botón para editar usuario con tooltip -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="primary" v-bind="attrs" v-on="on" @click="openEditDialog(item)">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>Editar Usuario</span>
            </v-tooltip>

            <!-- Botón para impersonar usuario con tooltip -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="rgb(128 97 194)" v-bind="attrs" v-on="on" @click="impersonateUser(item.id)">
                        <v-icon>mdi-account-switch</v-icon>
                    </v-btn>
                </template>
                <span>Impersonar Usuario</span>
            </v-tooltip>

            <!-- Botón para ver los links del harmony con tooltip -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="rgb(223 165 27)" v-bind="attrs" v-on="on" @click="goToLinks(item.email)">
                        <v-icon>mdi-link-variant</v-icon>
                    </v-btn>
                </template>
                <span>Ver Enlaces</span>
            </v-tooltip>

            <!-- Botón para ir a Harmonys del usuario con tooltip -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="green" v-bind="attrs" v-on="on" @click="goToHarmonys(item)">
                        <v-icon>mdi-vector-link</v-icon>
                    </v-btn>
                </template>
                <span>Ver Harmonys</span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="rgb(255, 87, 34)" v-bind="attrs" v-on="on" @click="openEmailDialog(item)">
                        <v-icon>mdi-email-arrow-right-outline</v-icon>
                    </v-btn>
                </template>
                <span>Enviar Email</span>
            </v-tooltip>

            <!-- Boton para asignar enlace de referido -->
            <!--<v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="#b21892" v-bind="attrs" v-on="on" @click="assignLinkReferral(item.id)">
              <v-icon>mdi-braille</v-icon>
            </v-btn>
          </template>
          <span>Asignar enlace referido</span>
        </v-tooltip>-->
        </template>
    </v-data-table>

    <!-- Modal para editar usuario -->
    <v-dialog transition="dialog-top-transition" max-width="600" v-model="editDialog">
        <v-card>
            <v-card-text class="py-0 px-0">
                <v-form ref="formEditUser" lazy-validation>
                    <v-alert border="right" colored-border color="#8e84c0" elevation="0" class="col-12 px-10 py-10 pb-4 mb-0">

                        <div class="text-left">
                            <v-row class="mb-0">
                                <h2 class="mb-2 mt-1 ml-2 mt-0 title-modal">Actualizar información</h2>
                            </v-row>
                            <p class="mb-8 desc">Los datos de <strong>{{ editedUser.email }}</strong>, serán actualizados.</p>
                        </div>
                        <div class="text-left">
                            <v-row>
                                <v-text-field id="first_name" v-model="editedUser.first_name" label="Nombre" type="text" outlined clearable color="#8e84c0" class="mb-0 input-capitalize col-12 col-md-6 px-3" :rules="[rules.required, rules.alpha, rules.minName, rules.maxName]"></v-text-field>

                                <v-text-field v-model="editedUser.last_name" id="last_name" label="Apellido" type="text" outlined clearable color="#8e84c0" class="mb-3 mb-md-2 input-capitalize col-12 col-md-6 px-3" :rules="[rules.required, rules.alpha, rules.minName, rules.maxName]"></v-text-field>
                            </v-row>

                            <v-text-field v-model="editedUser.email" id="email" name="email" label="Email" type="email" outlined clearable color="#8e84c0" class="mb-0" :rules="[rules.required, rules.email]"></v-text-field>

                            <v-row>
                                <div class="col-12 col-md-12 p-0 m-0">
                                    <v-select v-model="editedUser.role_id" hide-details :items="roles" item-text="name" item-value="id" outlined label="Rol" color="#8e84c0" :rules="[rules.required]" class="col-12 col-md-6">
                                    </v-select>
                                </div>
                            </v-row>

                            <v-select v-model="editedUser.status_id" :items="status" item-text="text" item-value="value" outlined label="Estado" color="#8e84c0" :rules="[rules.required]" class="col-12 col-md-6 mt-8">
                            </v-select>

                            <v-row>
                                <div class="col-12 col-md-4 pb-0 m-0">
                                    <v-autocomplete v-model="editedUser.phone_code" :items="phone_codes" item-text="dial_code" item-value="dial_code" color="#8e84c0" outlined label="Código" class="pr-0 pr-md-1" :rules="[rules.required]">
                                        <template v-slot:selection="{ item }">
                                            <v-avatar size="24">
                                                <img :src="item.flag" />
                                            </v-avatar>
                                            <span class="ml-2">{{ item.dial_code }}</span>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            <v-avatar size="24">
                                                <img :src="item.flag" />
                                            </v-avatar>
                                            <span class="ml-2">{{ item.dial_code }}</span>
                                        </template>
                                    </v-autocomplete>
                                </div>

                                <div class="col-12 col-md-8 py-0 pb-2 py-md-3">
                                    <v-text-field id="phone_number" v-model="editedUser.phone_number" label="Teléfono" type="text" outlined clearable color="#8e84c0" class="mb-0 pl-0 pl-md-1" :rules="[rules.required, rules.numericPhone, rules.minPhone, rules.maxPhone]"></v-text-field>
                                </div>
                            </v-row>

                            <v-text-field v-model="editedUser.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" label="Contraseña" counter color="#8e84c0" outlined clearable @click:append="show1 = !show1"></v-text-field>
                        </div>
                        <div class="text-right">
                            <v-btn :disabled="!isFormValid || saveLoading" @click="updateUser" :loading="saveLoading" color="#8e84c0" class="ml-0 white--text link ma-2 rounded-md px-16 col-12 col-md-5">
                                <v-icon left color="white">mdi-account-check-outline</v-icon>
                                Actualizar
                            </v-btn>
                        </div>
                    </v-alert>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Modal para ampliar el logo -->
    <v-dialog v-model="dialog" max-width="14rem" class="rounded-lg">
        <v-img :src="selectedLogo" width="14rem"></v-img>
    </v-dialog>

    <!-- Modal para enviar email -->
    <ModalSendEmail :dialogShow.sync="editDialogMail" :emailSend="emailSend" :userId="userId" @close="editDialogMail = false"></ModalSendEmail>
</v-col>
</template>

<script>
import axios from '@/axios.js';
import ModalSendEmail from '../../components/partials/admin/ModalSendEmail.vue';
import impersonationMixin from '@/mixins/impersonationMixin';

export default {
    name: 'Users',
    mixins: [impersonationMixin],
    components: {
        ModalSendEmail
    },
    data() {
        return {
            users: [],
            roles: [],
            genders: [],
            ages: [],
            creator_types: [],
            status: [{
                    text: 'Habilitado',
                    value: 1
                },
                {
                    text: 'Deshabilitado',
                    value: 2
                }
            ],
            owc: [],
            userId: 0,
            emailSend: '',
            subject: '',
            message: '',
            search: '',
            editDialogMail: false,
            loading: true,
            editDialog: false,
            editedUser: {},
            phone_codes: [],
            saveLoading: false,
            dialog: false,
            selectedLogo: null,
            show1: false,
            page: 1,
            perPage: 50,
            totalUsers: 0,
            userOrCreator: 0,
            // Nuevos campos para ordenamiento
            orderBy: 'created_at',
            orderDirection: 'desc',
            orderByOptions: [{
                    text: 'Fecha de registro',
                    value: 'created_at'
                },
                {
                    text: 'Total de vistas',
                    value: 'total_visits'
                },
                {
                    text: 'Total Harmonys',
                    value: 'total_sites'
                },
                {
                    text: 'Peso Total',
                    value: 'total_weight'
                }, // Nuevo campo
            ],
            orderDirectionOptions: [{
                    text: 'Mayor a menor',
                    value: 'desc'
                },
                {
                    text: 'Menor a mayor',
                    value: 'asc'
                },
            ],
            userOptions: [{
                    text: 'Todos',
                    value: 0
                },
                {
                    text: 'Admin',
                    value: 1
                },
                {
                    text: 'User',
                    value: 2
                },
                {
                    text: 'Creator',
                    value: 3
                },
            ],
            headers: [{
                    text: 'Avatar',
                    value: 'logo_path',
                    align: 'start',
                    sortable: false
                },
                {
                    text: 'Nombre',
                    value: 'name',
                    align: 'start'
                },
                {
                    text: 'Email',
                    value: 'email'
                },
                {
                    text: 'Teléfono',
                    value: 'phone'
                },
                {
                    text: 'Harmonys',
                    value: 'total_sites'
                },
                {
                    text: 'Rol',
                    value: 'role.name',
                    align: 'center'
                },
                {
                    text: 'Estado',
                    value: 'status_id',
                    align: 'center'
                },
                {
                    text: 'Fecha de registro',
                    value: 'created_at'
                },
                {
                    text: 'Opciones',
                    value: 'actions',
                    sortable: false
                },
            ],
            rules: {
                required: value => !!value || 'Este campo es requerido',
                min: v => v.length >= 8 || 'Mínimo 8 caracteres',
                minPhone: v => v.length >= 6 || 'Mínimo 6 dígitos',
                maxPhone: v => v.length <= 12 || 'Máximo 12 dígitos',
                numericPhone: v => /^[0-9]+$/.test(v) || 'Solo números',
                alpha: v => /^[a-zA-ZÑñ ]*$/.test(v) || 'Solo letras',
                minName: v => v.length >= 3 || 'Mínimo 3 caracteres',
                maxName: v => v.length <= 30 || 'Máximo 30 caracteres',
                email: value => {
                    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                    return pattern.test(value) || 'Email inválido'
                }
            }
        };
    },
    computed: {
        isFormValid() {
            return (
                this.editedUser.first_name &&
                this.editedUser.last_name &&
                this.editedUser.email &&
                this.editedUser.phone_code &&
                this.editedUser.phone_number &&
                this.editedUser.role_id
            );
        },
    },
    watch: {
        // Reiniciar la página cuando cambie el ordenamiento
        orderBy(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.page = 1;
                this.fetchUsers();
            }
        },
        orderDirection(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.page = 1;
                this.fetchUsers();
            }
        },
    },
    created() {
        this.fetchUsers();
        this.getPhoneCodes();
        this.getRoles();
        this.getGenders();
        this.getAges();
        this.getCreatorTypes();
    },
    methods: {
        showLogo(logoPath) {
            this.selectedLogo = logoPath ? logoPath : require('../../assets/illustrations/no-photo.jpg');
            this.dialog = true;
        },

        getCreatorColor(creator_id) {
            if (creator_id === 1) {
                return '#ed6f33';
            } else if (creator_id === 2) {
                return '#62a1e1';
            }else if (creator_id === 3) {
                return '#e162ba';
            }else if (creator_id === 4) {
                return '#00936a';
            }
            return 'grey';
        },

        async fetchUsers() {
            this.loading = true;
            try {
                const response = await axios.get('/admin/get-users', {
                    params: {
                        page: this.page,
                        per_page: this.perPage,
                        search: this.search,
                        order_by: this.orderBy,
                        order_direction: this.orderDirection,
                        filter: this.userOrCreator
                    }
                });
                this.users = response.data.users.data;
                this.totalUsers = response.data.users.total;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        onSearch() {
            this.page = 1;
            this.fetchUsers();
        },
        onOrderChange() {
            this.page = 1;
            this.fetchUsers();
        },
        onPageChange() {
            this.fetchUsers();
        },
        changePerPage(newPerPage) {
            this.perPage = newPerPage;
            this.page = 1; // Reiniciar a la primera página al cambiar los elementos por página
            this.fetchUsers();
        },
        getRoleColor(roleName) {
            if (roleName.toLowerCase() === 'admin') {
                return 'rgb(247 115 115)';
            } else if (roleName.toLowerCase() === 'user') {
                return '#a9a9a9';
            }
            return 'blue'; // Color por defecto
        },
        openEditDialog(user) {
            this.editedUser = {
                ...user
            };
            this.editDialog = true;
        },
        openEmailDialog(user) {
            this.userId = user.id;
            this.emailSend = user.email;
            this.editDialogMail = true;
        },
        async updateUser() {
            this.saveLoading = true;
            try {
                await axios.post('/admin/update-user', this.editedUser);

                this.$vs.notify({
                    title: 'Actualización',
                    text: 'Se han guardado los datos correctamente',
                    color: 'success',
                    icon: 'check_box',
                    position: 'bottom-center',
                    time: 5000,
                });

                this.editDialog = false;
                this.fetchUsers();
            } catch (error) {
                this.$vs.notify({
                    title: 'Error',
                    text: 'No se ha podido actualizar los datos',
                    color: 'danger',
                    icon: 'warning',
                    position: 'bottom-center',
                    time: 5000,
                });
                console.error(error);
            } finally {
                this.saveLoading = false;
            }
        },
        async getPhoneCodes() {
            try {
                const response = await axios.get('/get-phone-codes');
                this.phone_codes = response.data.map(code => {
                    code.flag = `https://flagcdn.com/24x18/${code.code.toLowerCase()}.png`;
                    return code;
                });
            } catch (error) {
                console.error(error);
            }
        },
        async getRoles() {
            try {
                const response = await axios.get('/admin/get-roles'); // Nueva llamada para obtener los roles
                this.roles = response.data.roles;
            } catch (error) {
                console.error(error);
            }
        },
        async getGenders() {
            try {
                const response = await axios.get('/admin/get-genders');
                this.genders = response.data.genders;
            } catch (error) {
                console.error(error);
            }
        },
        async getAges() {
            try {
                const response = await axios.get('/admin/get-ages');
                this.ages = response.data.ages;
            } catch (error) {
                console.error(error);
            }
        },
        async getCreatorTypes() {
            try {
                const response = await axios.get('/admin/get-creator-types');
                this.creator_types = response.data.creatorTypes;
            } catch (error) {
                console.error(error);
            }
        },
        goToHarmonys(user) {
            const routeData = this.$router.resolve({
                name: 'Harmonys',
                query: {
                    search: user.email
                }
            });

            window.open(routeData.href, '_blank');
        },
        goToLinks(user) {
            const routeData = this.$router.resolve({
                name: 'Links',
                query: {
                    search: user
                }
            });

            window.open(routeData.href, '_blank');
        },

        async assignLinkReferral(id) {
            this.$swal.fire({
                title: 'Estas seguro',
                text: 'Deseas asignar enlace de referido a este usuario',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#8E84C0',
                cancelButtonColor: '#eb7c7c',
                confirmButtonText: 'Si,asignar!',
                cancelButtonText: this.$t('panelHome.confirmDelete-cancelButtonText'),
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('/admin/assign-link-referral', {
                        id: id
                    }).then((response) => {
                        if (response.data.success) {
                            this.$swal.fire({
                                position: "top-center",
                                icon: "success",
                                title: "Se asigno código correctamente",
                                showConfirmButton: true,
                                willClose: () => {
                                    window.location.reload();
                                }
                            });
                        }
                    });
                }
            });
        },
        copy() {
            this.$vs.notify({
                title: 'Copiado',
                text: 'Enlace para referir<br>copiado correctamente',
                color: '#8E84C0',
                icon: 'content_copy',
                position: 'bottom-center',
                time: 5000,
            });
        },
    }
};
</script>

<style scoped>
.clickable-avatar:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease;
}

.title {
    font-size: 22px !important;
    color: #8061c2;
}

.desc {
    color: #737373;
    font-size: 15px;
}
</style>
